<template>
  <div class="complete">
    <div style="margin-left:24px">
      <p style="font-weight: 600; margin-bottom: 20px; font-size:12px;">随访任务</p>
    </div>
    <div class="header">
      <div class="patient-info">
        <div class="patient-name">{{userData.name}}</div>
        <div class="patient-age cut-line">病历号: <span style="color: #131414">{{userData.medicalRecordNumber}}</span></div>
        <div class="patient-age">CIED品牌型号: <span style="color: #131414">{{userData.ciedModel}}</span></div>
      </div>
      <div>
        <el-button class="header-btn" type="normal" @click="handleBack">返回</el-button>
        <el-button class="header-btn" type="primary" @click="handleSave">保存</el-button>
      </div>
    </div>
    <div class="complete-body">
       <div class="body-modular3">
         <div class="modular3-item">
          <span class="info-title">程控报告日期</span>
          <span style="color:#F56C6C;margin-right: 3px;">*</span>
            <el-date-picker
              v-model="formInfo.visitTime"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="timestamp"
              placeholder="请选择程控报告日期"
              :picker-options="pickerOptions"
              style="width:76%"
              @change="onChangeVisitTime">
            </el-date-picker>
            <el-popover
              placement="bottom"
              title=""
              width="230"
              trigger="hover"
              content="请选择程控文件中的随访日期">
              <div slot="reference" class="red-mark"></div>
            </el-popover>
          </div>
          <div class="modular3-item-malAdaptation">
            <span class="info-title-malAdaptation">不适症状 </span>
            <el-input
            v-model="formInfo.malAdaptation"
            placeholder="请输入不适症状"
            @input="onInputMalAdaptation"></el-input>
          </div>
       </div>
      <div class="body-modular1">
        <div class="body-title">
            <img src="https://f-u-system.oss-cn-beijing.aliyuncs.com/chengkong.png"
            style="
            width: 24px;
            height: 24px;
            margin: 0 4px 0 16px;"/>
          <span>
            上传完整版随访程控文档
          </span>
        </div>
        <div class="body-content">
          <el-upload
            class="upload-demo"
            drag
            action=""
            accept=".pdf,.PDF"
            :show-file-list="false"
            :http-request="uploadControlReq"
            :before-upload="beforeAvatarUpload"
            >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              点击上传、拖拽/粘贴文件到这里上传（只限1个文件）
              <br>
              <span>支持文件格式：pdf</span>
            </div>
          </el-upload>
          <div class="content-example">
            <div class="example-file" v-if="formInfo.report && formInfo.report.filename">
              <div class="file">
                <div class="file-info">
                  <div class="file-info__icon">
                    <img src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-pdf.png" style="width:36px;height: 36px;" alt="">
                  </div>
                  <div class="file-info__desc">
                    <div class="desc-title">{{formInfo.report.filename}}.pdf</div>
                  </div>
                </div>
                <div style="min-width: 40px;">
                  <a target="_blank" class='file-preview' :href="formInfo.report.url"></a>
                  <div class="file-delete" @click="handelDeleteControlReport"></div>
                </div>
              </div>
            </div>
            <img v-else class="example-img" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/reportExample.jpeg" alt="" />
          </div>
        </div>
      </div>
      <div class="body-modular2">
        <div class="body-title">
          <img src="https://f-u-system.oss-cn-beijing.aliyuncs.com/fujian.png"
          style="width: 24px; height: 24px; margin: 0 4px 0 16px;"/>
          <span>其他附件</span>
        </div>
        <div class="body-content">
          <el-upload
            class="upload-demo"
            drag
            accept=".pdf,.PDF,.jpg,.JPG,.png,.PNG,.jpeg,.JPEG"
            :show-file-list="false"
            action=""
            multiple
            :http-request="uploadFujianReq"
            :before-upload="beforeFilesUpload"
            >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              点击上传、拖拽/粘贴文件到这里上传（支持批量上传）
              <br>
              <span>支持文件格式：pdf、jpg、jpeg、png</span>
            </div>
          </el-upload>
          <div class="files">
            <div class="files-item" v-for="(i, k) in formInfo.recordFiles" :key="k">
              <div class="item-info">
                <div class="item-info__icon">
                  <img v-if="['pdf', 'PDF'].indexOf(i.fileFormat) !== -1" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-pdf.png" alt="">
                  <img v-if="['doc', 'docx'].indexOf(i.fileFormat) !== -1" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-word.png" alt="">
                  <img v-if="['xls', 'xlsx'].indexOf(i.fileFormat) !== -1" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-excel.png" alt="">
                  <img v-if="['png', 'jpg', 'jpeg'].indexOf(i.fileFormat) !== -1" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-picture.png" alt="">
                </div>
                <div class="item-info__desc">
                  <div class="desc-title">{{i.filename}}</div>
                  <div v-if="i.status === 0" class="desc-size">{{i.sizeFormat}}</div>
                  <div v-else class="desc-fail">
                    上传失败。
                  </div>
                </div>
              </div>
              <div>
                <a v-if="i.status === 0" :href="i.url" target="_blank" class='item-preview'></a>
                <div v-else class="item-upload-again" @click="handelUploadAgain(i)"></div>
                <div class="item-delete" @click="handelDelete(k)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="选择设备型号"
      :visible.sync="dialogDeviceModelVisible"
      width="474px"
      min-height="219px">
      <el-form ref="form" label-width="80px">
        <el-form-item v-for="i in ciedBrandModelInfo" :key="i.code" :label="i.name">
          <el-select v-model="i.modelId" filterable allow-create placeholder="请选择" style="width:277px" @change="handeCiedBrandModel(i)">
            <el-option
              v-for="item in i.models"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeviceModelVisible = false">取 消</el-button>
        <el-button type="primary" @click="handelCiedBrandModel">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogTipsVisible"
      width="500px"
      min-height="219px"
      :before-close="handleCancelTips"
      center>
      <div>
        <p style="text-align: center;">{{`患者植入CIED品牌型号：${userData.ciedModel}`}}</p>
        <p style="text-align: center;">请确认程控随访PDF文件中CIED品牌型号与患者植入设备信息是否相同</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button style="width:332px; margin-bottom: 10px;" type="primary" @click="handelCiedBrandModel" >相同</el-button>
        <br>
        <el-button style="width:332px; margin-bottom: 10px;" @click="dialogTipsVisible = false" >不同，重选文件</el-button>
        <br>
        <el-button style="width:332px; margin-bottom: 10px;" @click="handelModifyPatientInfo" >不同，修改患者植入设备信息</el-button>
      </span>
    </el-dialog>
    <el-drawer
      title="患者植入设备信息"
      :visible.sync="drawer"
      :wrapperClosable="false"
      :close-on-press-escape="false">
      <div style="margin: 0 20px;">
        <div class="content-item">
          <p class="item-title">CIED品牌:</p>
          <el-radio-group v-model="newCiedBrand" @change="newChangeBrand">
            <el-radio v-for="(item, index) in brandDict" style="margin-bottom: 10px" :key="index" :label="item.code">{{item.name}}</el-radio>
            <el-input v-if="newCiedBrand === '5'" size="small" v-model="otherCiedBrand" placeholder="请输入其他品牌"></el-input>
          </el-radio-group>
        </div>
        <div class="content-item">
          <p class="item-title">CIED型号:</p>
          <el-select size="small" v-if="newCiedBrand && (newCiedBrand !== '5')"  filterable style="margin-right: 15px; margin-bottom: 10px; width: 100%;" class="el-checkbox-input" allow-create v-model="newCiedModel" :placeholder="brandPlaceholder">
            <el-option
              v-for="(m, c) in modelDict"
              :key="c"
              :label="m.name"
              :value="m.code"
            >
            </el-option>
            <el-option label="其他" value="0"></el-option>
          </el-select>
          <el-input v-if="newCiedBrand === '5' || newCiedModel === '0'" size="small" v-model="otherCiedModel" placeholder="请输入其他型号" ></el-input>
        </div>
        <div class="content-item">
          <p class="item-title">CIED序列号:</p>
          <el-input size="small" v-model="ciedSn" placeholder="请填写CIED序列号"></el-input>
        </div>
        <div class="drawer-footer">
        <el-button class="footer-btn" @click="drawer = false">取消</el-button>
        <el-button class="footer-btn" type="primary" @click="handelSaveImplant">保存及上传</el-button>
      </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ossInit } from '@/libs/utils/ali-oss-sdk'
import {
  getFollowUpRecordDetails,
  getFileList,
  addFollowUpAttachment,
  // finishFollowUp,
  getCiedTpl,
  getImplant,
  saveImplant,
  getDictionary,
  saveFollowUpRecord
} from '@/service/module/followup'
const moment = require('moment')
export default {
  data () {
    return {
      moment,
      formInfo: {
        patientId: null,
        visitTime: '', // 程控报告日期
        malAdaptation: '', // 不适症状
        recordFiles: [], // 附件
        report: {} // 程控报告
      },

      dialogDeviceModelVisible: false, // 选择设备类型弹窗是否显示
      dialogTipsVisible: false,

      ciedBrandModelInfo: [], // 品牌型号信息
      modelId: '', // 型号id
      brandId: '', // 品牌id
      ciedBrandSelect: [ // 美敦力设备列表
        { children: [] }
      ],
      ciedBrand: {
        yapeitext: '',
        bailiduotext: '',
        boketext: '',
        qitatext: '',
        model: null,
        key: null
      },
      ciedSn: '', // 品牌序列号
      newCiedBrand: '',
      newCiedModel: '',
      otherCiedBrand: '',
      otherCiedModel: '',
      id: null,

      result: {},
      controlFileName: '',
      userData: {
        name: '',
        medicalRecordNumber: null,
        ciedModel: ''
      },
      medtronicDict: [],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      drawer: false,

      patientId: this.$route.query.patientId,
      medicalRecordNumber: this.$route.query.medicalRecordNumber,
      name: this.$route.query.name,
      isNewFollowUp: this.$route.query.isNewFollowUp,
      isInputMalAdaptation: false, // 是否操作不适症状
      isChangeVisitTime: false, // 是否操作程控报告日期
      isUploadControlFile: false, // 是否上传程控文件
      isUploadEnclosureFile: false, // 是否上传附件
      recordId: null,
      brandDict: [],
      modelDict: []
    }
  },
  computed: {
    brandPlaceholder () {
      let placeholder = ''
      switch (this.newCiedBrand) {
        case '1': placeholder = '请选择美敦力型号'
          break
        case '2': placeholder = '请选择雅培型号'
          break
        case '3': placeholder = '请选择百多力型号'
          break
        case '4': placeholder = '请选择波科型号'
          break
        default: placeholder = ''
          break
      }
      return placeholder
    }
  },
  async created () {
    await this.getBrandData()
    await this.getImplantInfo(this.patientId)
    ossInit()
    if (!this.isNewFollowUp || this.isNewFollowUp === 'false') {
      console.log('继续随访', this.isNewFollowUp)
      await this.getFiles()
      this.getFollowUpRecordDetails()
      this.recordId = this.$route.query.recordId
    }
    console.log('新建随访')

    this.userData.name = this.name
    this.userData.medicalRecordNumber = this.medicalRecordNumber
    getCiedTpl().then(res => {
      if (res.data) {
        this.ciedBrandModelInfo = res.data
      }
    })
  },
  methods: {
    handleBack () {
      this.$router.back()
    },
    handleSave () {
      if (!this.formInfo.visitTime) {
        this.$message.error('请选择程控报告日期')
        return false
      }
      this.formInfo.patientId = this.patientId
      if (JSON.stringify(this.formInfo.report) === '{}') {
        this.formInfo.report = null
      }
      saveFollowUpRecord(this.formInfo).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '保存成功!'
          })
          this.recordId = res.data.id
          this.getFollowUpRecordDetails()
          this.$router.push({
            path: 'patientFiles',
            query: {
              patientId: this.patientId
            }
          })
        }
      })
    },
    getFileType (val) {
      return val.substring(val.lastIndexOf('.') + 1)
    },
    async getBrandData () {
      const [err, res] = await this.$to(getDictionary('ciedBrand'))
      if (res && res.code === 200) {
        console.log('品牌字典:', res.data)
        this.brandDict = res.data
      } else {
        this.$message.error(res.message)
      }
      if (err) {
        this.$message.error(err.message)
        throw new Error(err.message)
      }
    },
    getImplantInfo (patientId) {
      getImplant(patientId).then(res => {
        if (res.data) {
          console.log('植入信息123：', res.data)
          const { ciedBrand, ciedModel, otherCiedBrand, otherCiedModel, ciedSn } = res.data
          this.newCiedBrand = ciedBrand
          this.newCiedModel = ciedModel
          this.otherCiedBrand = otherCiedBrand
          this.otherCiedModel = otherCiedModel
          this.ciedSn = ciedSn
          let brandName, modelDict, modelName
          if (!ciedBrand) {
            brandName = ''
            modelName = ''
          } else if (ciedBrand !== '5') {
            brandName = this.brandDict.find(o => o.code === ciedBrand).name
            modelDict = this.brandDict.find(o => o.code === ciedBrand).models
            if (!ciedModel) {
              modelName = ''
            } else if (ciedModel !== '0') {
              modelName = modelDict.find(o => o.code === ciedModel).name
            } else {
              modelName = otherCiedModel
            }
          } else {
            brandName = otherCiedBrand
            modelName = otherCiedModel
          }
          this.userData.ciedModel = brandName + modelName
          // const
          // const modelName =

          // this.ciedBrandSelect = res.data.ciedBrandSelect
          // if (res.data.ciedBrand) {
          //   this.ciedBrand = res.data.ciedBrand
          // }
          // this.ciedSn = res.data.ciedSn
          this.id = res.data.id
          // if (res.data.ciedBrand && typeof (res.data.ciedBrand.model) === 'string') {
          //   const obj = {
          //     code: 0,
          //     title: this.ciedBrand.model
          //   }
          //   this.ciedBrandSelect.push(obj)
          // }
          // getDictionary('ciedBrand').then(res => {
          //   if (res && res.code === 200) {
          //     this.brandDict = res.data
          //     this.medtronicDict = res.data.find(o => o.code === '1').children
          //     if (this.medtronicDict) {
          //       this.treatmentModel(this.ciedBrand, this.medtronicDict)
          //     }
          //   }
          // })
        }
      })
    },
    conver (limit) {
      var size = ''
      if (limit < 0.1 * 1024) { // 如果小于0.1KB转化成B
        size = limit.toFixed(2) + 'B'
      } else if (limit < 0.1 * 1024 * 1024) { // 如果小于0.1MB转化成KB
        size = (limit / 1024).toFixed(2) + 'KB'
      } else if (limit < 0.1 * 1024 * 1024 * 1024) { // 如果小于0.1GB转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
      } else { // 其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
      }

      var sizestr = size + ''
      var len = sizestr.indexOf('.')
      var dec = sizestr.substr(len + 1, 2)
      if (dec === '00') { // 当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
      }
      return sizestr
    },
    async uploadFujianReq (file) {
      const size = this.conver(file.file.size)
      const fileType = this.getFileType(file.file.name)
      const filename = new Date().getTime() + '-' + file.file.name
      let headers
      switch (fileType) {
        case 'pdf' || 'PDF' : headers = {}
          break
        default: headers = { 'Content-Type': 'image/jpg' }
          break
      }
      const OSS = this.$store.getters['Oss/getOss']
      const result = await OSS.put(`fu-files/${filename}`, file.file, { headers })
      const uploadItem = {
        filename: file.file.name,
        path: result.name,
        size: file.file.size.toString(),
        sizeFormat: size,
        fileFormat: fileType,
        status: 0,
        bucketName: process.env.NODE_ENV === 'production' ? 'fu-period-attachment' : 'fu-period-attachment-test',
        url: OSS.signatureUrl(`fu-files/${filename}`)
      }
      this.formInfo.recordFiles.push(uploadItem)
      console.log('附件文件信息:', this.formInfo.recordFiles)
    },
    async uploadControlReq (file) {
      const filename = new Date().getTime() + '-' + file.file.name
      this.controlFileName = file.file.name.substring(0, file.file.name.lastIndexOf('.'))
      this.formInfo.report.size = file.file.size.toString()
      const OSS = this.$store.getters['Oss/getOss']
      this.$set(this.formInfo.report, 'url', OSS.signatureUrl(`cied-reports/${filename}`))
      const headers = {}
      this.result = await OSS.put(`cied-reports/${filename}`, file.file, { headers })
    },
    addFollowUpAttachment (data) {
      addFollowUpAttachment(data).then(res => {
        if (res.code === 200) {
          this.formInfo.recordFiles.push(data)
          this.getFiles()
        } else {
          this.$message.error('上传失败')
        }
      })
    },
    async getFiles () {
      const [err, res] = await this.$to(getFileList(this.$route.query.recordId))
      if (res && res.code === 200) {
        this.formInfo.recordFiles = res.data || []
      } else {
        this.$message.error('网络错误')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    handelDelete (i) {
      this.formInfo.recordFiles.splice(i, 1)
    },
    handelUploadAgain (i) {
      const params = {
        ...i,
        status: 0,
        bucketName: process.env.NODE_ENV === 'production' ? 'fu-period-attachment' : 'fu-period-attachment-test',
        recordId: Number(this.$route.query.recordId) // 随访记录id
      }
      this.formInfo.recordFiles.map(item => {
        if (item.filename === i.filename) {
          params.path = item.path
          params.url = item.url
        }
      })
      this.addFollowUpAttachment(params)
      console.log('重新上传：', params)
    },
    getFollowUpRecordDetails () {
      getFollowUpRecordDetails(this.$route.query.recordId).then(res => {
        if (res.data) {
          this.formInfo = res.data
          if (!this.formInfo.report) {
            this.formInfo.report = {}
          }
          if (this.formInfo.recordFiles.length > 0) {
            this.formInfo.recordFiles.map(i => {
              i.status = 0
            })
          }
        }
      })
    },
    beforeAvatarUpload () {
      if (this.formInfo.report === {}) {
        this.$message.warning('只限1个文件')
        return
      }
      this.isUploadControlFile = true
      if (this.userData.ciedModel) {
        this.dialogTipsVisible = true
      } else {
        this.dialogDeviceModelVisible = true
        getCiedTpl().then(res => {
          if (res.data) {
            this.ciedBrandModelInfo = res.data
          }
        })
      }
    },
    beforeFilesUpload () {
      this.isUploadEnclosureFile = true
    },
    handelCiedBrandModel () {
      this.formInfo.report = {
        filename: this.controlFileName,
        path: this.result.name, // 相对位置
        bucketName: process.env.NODE_ENV === 'production' ? 'fu-period-attachment' : 'fu-period-attachment-test',
        brandCode: this.brandId, // 品牌code码
        modelCode: this.modelId.toString(), // 型号code码
        otherModelName: this.otherModelName,
        status: 0,
        url: this.formInfo.report.url
      }
      console.log('程控报告文件信息:', this.formInfo.report)
      this.dialogDeviceModelVisible = false
    },
    handelDeleteControlReport () {
      this.formInfo.report = {}
    },
    handeCiedBrandModel (i) {
      let isFind = false
      this.brandId = i.code
      i.models.map(itemer => {
        if (itemer.code === i.modelId) {
          this.modelId = i.modelId
          isFind = true
        }
      })

      if (isFind === false) {
        this.otherModelName = i.modelId
        this.modelId = 0
      }

      this.ciedBrandModelInfo.map(item => {
        if (i.code !== item.code) {
          item.modelId = null
        }
      })
      this.$forceUpdate()
    },
    treatmentModel (ciedBrand, medtronicDict) { // 处理型号
      if (ciedBrand.key !== null) {
        this.ciedBrandType = ciedBrand.key
        let detailStr = ''

        this.brandId = (ciedBrand.key + 1).toString()
        this.modelId = 0

        switch (ciedBrand.key) {
          case 0: // 美敦力
            this.userData.ciedModel = '美敦力'
            this.modelId = ciedBrand.model
            if (ciedBrand.mode !== null) { // 已选择型号
              if (typeof ciedBrand.model === 'string') { // 自填型号
                detailStr = ciedBrand.model
              }
              if (typeof ciedBrand.model === 'number') { // 枚举型号
                detailStr = medtronicDict.find(o => o.code === ciedBrand.model).title
              }
            }
            break
          case 1: // 雅培
            this.userData.ciedModel = '雅培'
            detailStr = ciedBrand.yapeitext
            break
          case 2: // 百多力
            this.userData.ciedModel = '百多力'
            detailStr = ciedBrand.bailiduotext
            break
          case 3: // 波科
            this.userData.ciedModel = '波科'
            detailStr = ciedBrand.boketext
            break
          case 4: // 其他
            this.userData.ciedModel = '其他'
            detailStr = ciedBrand.qitatext
            break
        }
        this.userData.ciedModel += detailStr
        this.otherModelName = detailStr
      }
    },
    handelModifyPatientInfo () {
      this.drawer = true
      const { patientId } = this.$route.query
      this.getImplantInfo(patientId)
      this.dialogDeviceModelVisible = false
      this.dialogTipsVisible = false
    },
    changeCiedBrand (val) {
      this.ciedBrand.key = val
      switch (val) {
        case 0:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          break
        case 1:
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 2:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 3:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 4:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.model = null
          break
      }
    },
    handelSaveImplant () {
      saveImplant({
        ciedSn: this.ciedSn,
        ciedBrand: this.newCiedBrand,
        ciedModel: this.newCiedModel,
        otherCiedBrand: this.otherCiedBrand,
        otherCiedModel: this.otherCiedModel,
        id: this.id
      }).then(res => {
        if (res && res.code === 200) {
          this.$message.success('保存成功')
          this.getImplantInfo(this.patientId)
          // this.handelCiedBrandModelTest()
          this.newCiedModel = ''
          this.newCiedBrand = ''
          this.otherCiedBrand = ''
          this.otherCiedModel = ''
          this.ciedSn = ''
          this.drawer = false
        } else {
          this.$message.error('保存失败')
        }
      }).catch((err) => {
        this.$message.error(err)
      })
    },
    handleCancelTips () {
      this.dialogTipsVisible = false
    },
    onChangeVisitTime () {
      this.isChangeVisitTime = true
    },
    onInputMalAdaptation () {
      this.isInputMalAdaptation = true
    },
    newChangeBrand (val) {
      this.newCiedBrand = val
      if (this.newCiedBrand !== '5') {
        const curOpt = this.brandDict.find(i => i.code === val)
        console.log(curOpt)
        this.modelDict = curOpt.models
        this.otherCiedBrand = ''
        this.newCiedModel = ''
      } else {
        this.newCiedModel = '0'
      }
      this.otherCiedModel = ''
    }
  }
}
</script>

<style scoped lang="scss">
  @import "src/style/common.scss";
  .complete {
  height: 100%;
  color: #131414;
  font-weight: 500;
  font-size: 16px;
  .header {
    display: flex;
    box-shadow: inset 0px -1px 0px #DCDFE6;
    justify-content: space-between;
    padding: 0 24px 16px;
    background: #fff;
    &-info {
      display: flex;
      align-items: center;
      .info-title {
        @include fs(#131414, 20px, 700);
      }
      .info-subtitle {
        @include fs(#606266, 16px, 400);
      }
      ::v-deep .el-divider--vertical {
        margin: 0 12px;
      }
    }
  }
  .complete-body {
    width: 100%;
    height: 100%;
    padding: 0 24px;
    .body-modular1,.body-modular2,.body-modular3 {
      border: 1.5px solid #E7E8EA;
      border-radius: 5px;
      margin-top: 16px;
      background: #fff;
      .body-title {
        // background: #E5E5E5;
        box-shadow: inset 0px -1px 0px #E7E8EA;
        height: 44px;
        line-height: 44px;
        display: flex;
        display: flex;
        align-items: center;
        flex-direction: row;
      }
      .body-content {
        height: 194px;
        display: flex;
        flex-direction: row;
        margin: 16px;
        .upload-demo {
          flex:1;
          margin-right: 24px;
          height: 162px;
        }
        .content-example {
          flex:1;
          height: 162px;
          border: 1.5px solid #E7E8EA;
          border-radius: 5px;
          .example-img {
            width: 100%;
            height: 100%;
          }
          .example-file {
            padding: 0 12px;
            width: 100%;
            height: 100%;
          }
        }
      }
      .file {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .file-info {
          display: flex;
          &__icon {
            @include domSize(36px, 36px);
            margin-right: 8px;
            img {
              @include domSize(100%, 100%);
            }
          }
          &__desc {
            .desc-title {
              @include fs(#131414, 14px, 400);
            }
            .desc-size {
              @include fs(#606266, 12px, 400);
            }
            .desc-parsing {
              @include fs(#409EFF, 12px, 400);
            }
            .desc-fail {
              font-weight: 400;
              font-size: 12px;
              line-height: 12px;
              color: #F56C6C;
              margin-top: 2px;
              &::before {
                content: ' ';
                background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/exclamation-circle.png") no-repeat;
                background-size: 100% 100%;
                display: inline-block;
                @include domSize(11px, 11px);
              }
            }
          }
        }
        .file-preview,.file-upload-again {
          background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-preview.svg") no-repeat;
          background-size: 100% 100%;
          display: inline-block;
          @include domSize(12.83px, 9.92px);
          cursor: pointer;
        }
        .file-upload-again {
          background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/upload-again.svg") no-repeat;
            @include domSize(11.67px, 11.67px);
        }
        .file-delete {
          background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/delete.svg") no-repeat;
          background-size: 100% 100%;
          display: inline-block;
          margin-left: 13px;
          @include domSize(12.83px, 11.67px);
          cursor: pointer;
        }

      }
    }
    .body-modular2 {
      min-height: 376px;
      .body-content {
        height: 100%;
      .files {
        flex:1;
        &-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #DCDFE6;
          border-radius: 5px;
          margin-bottom: 8px;
          padding: 0 12px;
          cursor: pointer;
          @include domSize(100%, 60px);
          .item-info {
            display: flex;
            &__icon {
              @include domSize(36px, 36px);
              margin-right: 8px;
              img {
                @include domSize(100%, 100%);
              }
            }
            &__desc {
              .desc-title {
                @include fs(#131414, 14px, 400);
              }
              .desc-size {
                @include fs(#606266, 12px, 400);
              }
              .desc-fail {
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #F56C6C;
                margin-top: 2px;
                &::before {
                  content: ' ';
                  background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/exclamation-circle.png") no-repeat;
                  background-size: 100% 100%;
                  display: inline-block;
                  @include domSize(11px, 11px);
                }
              }
            }
          }
          .item-preview,.item-upload-again {
            background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-preview.svg") no-repeat;
            background-size: 100% 100%;
            display: inline-block;
            @include domSize(12.83px, 9.92px);
          }
          .item-upload-again {
            background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/upload-again.svg") no-repeat;
            background-size: 100% 100%;
             @include domSize(11.67px, 11.67px);
          }
          .item-delete {
            background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/delete.svg") no-repeat;
            background-size: 100% 100%;
            display: inline-block;
            margin-left: 13px;
            @include domSize(12.83px, 11.67px);
          }
        }
      }
      }
    }
    .body-modular3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap:nowrap;
      height: 64px;
      .modular3-item,.modular3-item-malAdaptation {
        display: flex;
        align-items: center;
        margin: 16px 0 16px 16px;
        order:-1;
        flex-grow:1;
        flex-wrap:nowrap;
      .info-title,.info-title-malAdaptation {
        font-weight: 400;
        font-size: 14px;
        color: #606266;
        width: 86px;
      }
      .info-title-malAdaptation {
        width: 65px;
      }
      }
      .modular3-item-malAdaptation {
      margin: 16px 16px 16px 0;
      flex-grow:1.25;
      }
    }
    ::v-deep .el-upload {
      width: 100%;
      height: 100%;
    }
  }
  .el-icon-upload {
    &::before {
      color: #409EFF;
    }
  }
  .el-icon-upload {
    font-size: 32px;
    line-height: 22px;
  }
  .el-upload-dragger .el-icon-upload {
    margin: 40px 0 20px;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
    height: 162px;
    line-height: 20px;
    background: rgba(64, 158, 255, 0.02);
    border: 1px dashed #409EFF;
    border-radius: 5px;
  }
  ::v-deep .el-upload__text {
    color: #409EFF;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    span {
      font-weight: 400;
      font-size: 12px;
      color: #79BBFF;
    }
  }
  .patient-info {
    display: flex;
    align-items: center;
    @include fs (#909399, 14px, 400);
    .patient-name {
      @include fs (#131414, 20px, 700);
      margin-right: 24px;
    }
    .cut-line {
      &:after {
        content: ' ';
        display: inline-block;
        background-color: #E7E8EA;
        margin: 0 8px;
        @include domSize(1px, 8px);
      }
    }
  }
  .red-mark {
    width: 14px;
    height: 14px;
    background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-gray-mark.png") no-repeat;
    margin-left: 5px;
    display: inline-block;
  }
}
.content-item {
  margin-bottom: 20px;
  .item-title {
    margin-bottom: 10px;
  }
}
.drawer-footer {
 position: fixed;
 bottom: 0;
 height: 60px;
 right: 20px;

}
</style>
